<template>
  
  <div class="card mb-5 mb-xl-10" v-loading="state.loaderEnabled">
    <div class="card-header border-0">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Détails de votre profil utilisateur</h3>
      </div>
    </div>

    <div class="card-body border-top p-9" >

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Prénom Nom</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-5 fv-row">
              <el-input type="text" class="mb-3 mb-lg-0" placeholder="Prénom" v-model="state.form.us_prenom" />
            </div>
            <div class="col-lg-7 fv-row">
              <el-input type="text" class="mb-3 mb-lg-0" placeholder="Prénom" v-model="state.form.us_nom" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Mail de connexion</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-12 fv-row">
              <el-input type="text" class="mb-3 mb-lg-0" placeholder="Mail de connexion" v-model="state.form.us_username" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-6" v-loading="state.loaderEnabledTest">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Votre Clé API Meraki</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-8 fv-row">
              <el-input type="text" class="mb-3 mb-lg-0" placeholder="Saisir votre clé API Meraki" v-model="state.form.us_api_key_meraki" id="keyMeraki" name="keyMeraki" autocomplete="keyMeraki"  />
            </div>
            <div class="col-lg-4 fv-row">
              <button type="button" class="btn btn-light-primary w-100"  @click="test_api()">
                <span class="indicator-label"> Tester la clé API </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-primary" @click="validateForm()">
        <span class="indicator-label"> Enregistrer les changements </span>
      </button>
    </div>
    
  </div>


  <div class="card mb-5 mb-xl-10" v-loading="state.loaderEnabledPass">
    <div class="card-header border-0">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Changement de mot de passe</h3>
      </div>
    </div>

    <div class="card-body border-top p-9">

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Ancien mot de passe</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <el-input type="password" class="mb-3 mb-lg-0" placeholder="Saisir votre ancien mot de passe" v-model="state.password.old_repassword" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Nouveau mot de passe</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <el-input type="password" class="mb-3 mb-lg-0" placeholder="Saisir votre nouveau mot de passe" v-model="state.password.new_password" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Resaisir le nouveau mot de passe</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <el-input type="password" class="mb-3 mb-lg-0" placeholder="Saisir une seconde fois votre nouveau mot de passe" v-model="state.password.new_repassword" />
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-primary" @click="changePassword()">
        <span class="indicator-label"> Enregistrer le nouveau mot de passe </span>
      </button>
    </div>
    
  </div>


</template>

<script lang="ts">

import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: "account-settings",
  components: {
  },
  setup() {
   
    const state = reactive({
      loaderEnabled : false,
      loaderEnabledTest : false,
      loaderEnabledPass : false,
      form : {} as any,
      password : {
        old_repassword: "",
        new_password: '',
        new_repassword: '',
      },
    });

    onMounted(async () => {
      state.form = await getAxios("/me");
    });


    const validateForm = async () => {
      
      const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!regexMail.test(state.form.us_username)){
        ElNotification({ title: 'Error', message: 'Le mail de connexion doit être un mail valide', type: 'error',})
        return false;
      } 

      if (!state.form.us_username || !state.form.us_prenom || !state.form.us_nom || !state.form.us_api_key_meraki){
        ElNotification({ title: 'Error', message: 'Un des champs obligatoire n\'est pas saisi', type: 'error',})
        return false;
      } 

      state.loaderEnabled = true;

      const form = JSON.stringify(state.form);
      await mAxiosApi.post("/validateFormProfil", form, { headers: {   'Content-Type': 'application/json'  }  });
      ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})

      state.loaderEnabled = false;
      
    };

    
    const changePassword = async () => {
      
      state.loaderEnabledPass = true;

      const form = JSON.stringify(state.password);
      const response = await mAxiosApi.post("/changePassword", form, { headers: {   'Content-Type': 'application/json'  }  });
      console.log(response);
      if(response.data.errors) {
        ElNotification({ title: 'Erreur', message: response.data.errors, type: 'error',})
      } else {
        ElNotification({ title: 'Succés', message: 'Changements effectués', type: 'success',})
      }
      

      state.loaderEnabledPass = false;
      
    };

    const test_api = async () => {
      state.loaderEnabledTest = true;
      const mOrga = await getAxios("/testapi?key="+state.form.us_api_key_meraki);
      state.loaderEnabledTest = false;
      if(mOrga.errors) {
        ElNotification({
          title: 'Error',
          message: 'Il semble y avoir une erreur avec la clé API saisie, veuillez la vérifier, svp',
          type: 'error',
        })
      } else {
        ElNotification({ title: 'Succés', message: 'La clé saisie semble fonctionnelle !', type: 'success',})
      }
    }

    return {
      state,
      validateForm,
      changePassword,
      test_api
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
